<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        {{ $t("ammountForApp") }}
        <v-spacer />
        <!-- <v-text-field
          v-model="search.name"
          type="search"
          append-icon="mdi-magnify"
          label="Search By Name"
          single-line
          hide-details
          class="mr-2"
          @keyup="fetchAllItems"
        />
        <v-text-field
          v-model="search.amount"
          type="search"
          append-icon="mdi-magnify"
          label="Search By Amount"
          single-line
          hide-details
          class="mr-2"
          @keyup="fetchAllItems"
        /> -->
      </v-card-title>
      <v-data-table
        :loading="dataLoading"
        :headers="headers"
        :items="drivers"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
        @fetchAllItems="fetchAllItems"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.driver.user.userName }}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          {{ item.driver.user.mobile }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ Math.abs(item.amount) }}
        </template>
        <template v-slot:[`item.bank`]="{ item }">
          {{ item.driver.bankName }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="teal"
                v-bind="attrs"
                v-on="on"
                @click="confirmSMS(item)"
              >
                <v-icon>
                  mdi-message-processing
                </v-icon>
              </v-btn>
            </template>
            <span>Send SMS</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="orange"
                v-bind="attrs"
                v-on="on"
                @click="sendNotification(item)"
              >
                <v-icon>
                  mdi-email
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("notify") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                outlined
                fab
                class="mx-2"
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="confirmBlock(item)"
              >
                <v-icon>
                  mdi-account-lock
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("block") }}</span>
          </v-tooltip>
        </template>
        <template
          v-slot:no-data
          loading
          loading-text="No Data Available"
        >
          <p>{{ $t("noData") }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog1"
      max-width="500"
    >
      <v-card
        v-model="driverData"
        class="text-center"
      >
        <base-material-card
          :title="$t('confirmBlock')"
          color="error"
        >
          <v-card-text class="mt-8">
            <p>{{ $t("areYouSureBlock") }} {{ driverName }}?</p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              outlined
              class="my-1 mx-1"
              :loading="loading"
              :disabled="disabled"
              @click="blockDriver(userId)"
            >
              {{ $t("block") }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="dialog1 = false"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      max-width="500"
    >
      <v-card
        v-model="driverData"
        class="text-center"
      >
        <base-material-card
          title="Notification Details"
          color="orange"
        >
          <v-card-title class="mt-8">
            <v-text-field
              v-model="notification.title"
              counter="25"
              outlined
              label="Notification Title"
              class="notification-title"
            />
            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="dialog2 = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="notification.body"
              outlined
              name="input-7-4"
              label="Notification Body"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              outlined
              class="my-1 mx-1"
              :loading="loading"
              :disabled="disabled"
              @click="sendNotifi(notification)"
            >
              {{ $t("send") }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="dialog2 = false"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog6"
      max-width="500"
    >
      <v-card
        class="text-center"
      >
        <base-material-card
          title="SMS Details"
          color="teal"
        >
          <v-card-title class="mt-8">
            <v-icon
              aria-label="Close"
              @click="dialog6 = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="SMS"
              outlined
              type="text"
              name="input-7-4"
              label="SMS Details"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              outlined
              class="my-1 mx-1"
              :disabled="disabled"
              :loading="loading"
              @click="sendSMS(driverData)"
            >
              {{ $t("send") }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="dialog6 = false"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <base-material-snackbar
      v-if="snackbar"
      v-model="snackbar"
      :type="color"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      {{ $t("massegeSentSuccss") }}
    </base-material-snackbar>
    <base-material-snackbar
      v-if="snackbar2"
      v-model="snackbar2"
      :type="color"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      {{ $t("somethingError") }}
    </base-material-snackbar>
    <base-material-snackbar
      v-if="snackbar3"
      v-model="snackbar3"
      color="green"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      {{ $t("driverIsBlocked") }}
    </base-material-snackbar>
  </v-container>
</template>
<script>
  import { ServiceFactory } from '../../../services/ServiceFactory'
  const paymetServices = ServiceFactory.get('payment')
  const driversService = ServiceFactory.get('drivers')
  export default {
    name: 'Drivers',
    data: (vm) => ({
      search: {
        name: '',
        amount: '',
      },
      loader: null,
      loading: false,
      disabled: false,
      dataLoading: false,
      drivers: [],
      driverData: {},
      driverId: '',
      userId: '',
      driverName: '',
      page: 0,
      total: 0,
      numberOfPages: 0,
      options: {},
      SMS: '',
      notification: {
        title: '',
        body: '',
        payload: {
          driverId: '',
          clientId: '',
        },
      },
      rules: [v => v.length <= 25 || 'Max 25 characters'],
      singleSelect: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog6: false,
      selected: [],
      color: 'info',
      colors: ['success'],
      direction: 'top center',
      snackbar: false,
      snackbar2: false,
      snackbar3: false,
      headers: [
        { text: vm.$t('name'), sortable: false, value: 'name' },
        { text: vm.$t('phone'), sortable: false, value: 'phone' },
        { text: vm.$t('deserveAmount'), value: 'amount', sortable: false },
        { text: vm.$t('bankName'), value: 'bank', sortable: false },
        { text: vm.$t('actions'), value: 'actions', sortable: false },
      ],
    }),

    computed: {
      parsedDirection () {
        return this.direction.split(' ')
      },
    },

    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
      deep: true,
      dialog (val) {
        val || this.close()
      },
      $route (to, from) {
        if (this.$route) {
        // console.log(this.$route)
        } else {
        }
      },
    },

    methods: {
      confirmSMS (item) {
        this.dialog6 = true
        this.driverData = item
        console.log(item)
      },
      confirmBlock (driver) {
        this.dialog1 = true
        this.driverData = driver.driver
        this.driverName = driver.driver.user.userName
        this.userId = driver.driver.user.id
      },
      getCarData (cars) {
        this.dialog = true
        this.carData = cars.cars
      },
      sendNotification (driver) {
        this.dialog2 = true
        this.notification = driver
      },

      randomColor () {
        this.color = this.colors[Math.floor(Math.random() * this.colors.length)]
      },

      editItem (item) {
        this.editedIndex = this.drivers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.drivers.indexOf(item)
        this.drivers.splice(index, 1)
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.drivers[this.editedIndex], this.editedItem)
        } else {
          this.drivers.push(this.editedItem)
        }
        this.close()
      },
      async fetchAllItems () {
        this.dataLoading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const drivers = await paymetServices.getCreditDrivers(
          itemsPerPage,
          page,
          pageNumber,
          this.search,
        )
        this.drivers = drivers.data.data
        this.total = drivers.data.total
        this.numberOfPages = drivers.data.pageCount
        this.dataLoading = false
      },
      async blockDriver (driverId) {
        this.disabled = true
        this.loading = true
        this.driverId = driverId.user.id
        console.log(this.driverId)
        const blockSucess = await driversService.blockDriver(this.driverId)
        if (blockSucess) {
          this.dialog1 = false
          this.snackbar3 = true
          this.disabled = false
          this.loading = false
          this.$router.go('/for-app')
        } else {
          this.snackbar2 = true
          this.disabled = false
          this.loading = false
        }
      },
      async sendNotifi (notification) {
        this.disabled = true
        this.loading = true
        this.notification = notification
        const userId = notification.driver.user.id
        const sendNotifiSucess = await driversService.sendNotifi(
          userId,
          notification,
        )
        if (sendNotifiSucess) {
          this.snackbar = true
          this.dialog2 = false
          this.disabled = false
          this.loading = false
          this.$router.go('/for-app')
        } else {
          this.snackbar2 = true
          this.disabled = false
          this.loading = false
        }
      },
      async sendSMS (driverData) {
        this.disabled = true
        this.loading = true
        const sendSMS = await driversService.sendSMS(
          this.driverData.driver.user.id,
          this.SMS,
        )
        if (sendSMS) {
          this.snackbar6 = true
          this.dialog6 = false
          this.disabled = false
          this.loading = false
          this.SMS = ''
        } else {
          this.snackbar2 = true
          this.disabled = false
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="scss">
.v-card .v-card--material__heading {
  top: 0px !important;
}
</style>
